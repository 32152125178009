<template>
  <div v-if="details" class="mt-8">
    <ul class="flex flex-wrap">
      <li class="w-full md:w-1/2 md:pr-4">
        <compare-box :kpi="rank" :year-a="year" :year-b="firstYear" />
      </li>
      <li class="w-full md:w-1/2 md:pr-4">
        <compare-box :kpi="value" :year-a="year" :year-b="firstYear" />
      </li>
      <li class="w-full md:w-1/2 md:pr-4">
        <compare-box :kpi="secrecy" :year-a="year" :year-b="firstYear" />
      </li>
      <li class="w-full md:w-1/2 md:pr-4">
        <compare-box :kpi="scale" :year-a="year" :year-b="firstYear" />
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CompareBox from '@/components/CompareBox'

export default {
  components: {
    CompareBox
  },
  computed: {
    ...mapGetters(['country', 'countryDetails', 'year', 'index']),
    rank() {
      return 'Rank'
    },
    value() {
      return this.index === 'fsi' ? 'FSI_Value' : 'Value'
    },
    secrecy() {
      return this.index === 'fsi' ? 'Secrecy_Score' : 'Score'
    },
    scale() {
      return 'Global_Scale_Weight'
    },
    firstYear() {
      return this.$store.getters.years[0]
    },
    details() {
      return this.countryDetails(this.country)
      // const country = details[this.year]
      // if (country) {
      //   return {
      //     name: country.Jurisdiction,
      //     rank: country.Rank,
      //     score: country.Secrecy_Score,
      //     globalScaleWeight: country.Global_Scale_Weight
      //   }
      // }
      // return {}
    }
  }
}
</script>
