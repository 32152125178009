<template>
  <div class="w-full h-24 md:h-32 flex justify-left items-center mr-4 mb-4 border-light-base border-2">
    <div
      class="w-1/3 h-full flex justify-center items-center text-3xl md:text-xl lg:text-3xl font-light text-center bg-light-base mr-2 text-white"
    >
      {{ value }}
    </div>
    <div class="h-full flex flex-col justify-between">
      <h2 class="text-2xl md:text-lg lg:text-2xl font-bold pt-2 md:pt-4">{{ friendlyTitle }}</h2>
      <span v-if="canCompare" class="text-base text-left pb-2 md:pb-4" v-html="compare" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { kpiToText } from '@/utils/text'
import { round } from '@/utils/numbers'

export default {
  props: {
    kpi: {
      type: String,
      required: true
    },
    yearA: {
      type: Number,
      required: true
    },
    yearB: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(['dimensions']),
    ...mapGetters(['country', 'countryDetails']),
    details() {
      return this.countryDetails(this.country)
      // const country = details[this.year]
      // if (country) {
      //   return {
      //     name: country.Jurisdiction,
      //     rank: country.Rank,
      //     score: country.Secrecy_Score,
      //     globalScaleWeight: country.Global_Scale_Weight
      //   }
      // }
      // return {}
    },
    friendlyTitle() {
      return kpiToText(this.kpi, true)
    },
    value() {
      if (this.details && this.details[this.yearA]) {
        if (this.kpi === 'Global_Scale_Weight') {
          const val = round(this.details[this.yearA][this.kpi] * 100)
          let prefix = val === 0 ? '<' : ''
          return `${prefix}${val} %`
        }
        return this.details && this.details[this.yearA] ? Math.round(this.details[this.yearA][this.kpi]) : ''
      }
      return ''
    },
    canCompare() {
      // only compare if in 2020
      if (this.yearA <= this.yearB) return false

      // only compare if there is data from the past
      return (
        this.details &&
        this.details.hasOwnProperty(this.yearA) &&
        this.details.hasOwnProperty(this.yearB) &&
        typeof this.details[this.yearA] !== 'undefined' &&
        typeof this.details[this.yearB] !== 'undefined'
      )
    },
    compare() {
      if (this.canCompare) {
        const current = this.details[this.yearA][this.kpi]
        let past = this.details[this.yearB][this.kpi]
        const diff = current - past
        let value = Math.abs(diff)

        if (diff === 0) {
          return `Same as in ${this.yearB}`
        }

        let direction = diff < 0 ? '↘' : '↗'
        if (this.kpi === 'Rank') {
          direction = diff > 0 ? '↘' : '↗'
        }

        if (this.kpi === 'Global_Scale_Weight') {
          value = `${round(value * 100)} %`
          past = `${round(past * 100)} %`
        } else {
          value = round(value)
          past = round(past)
        }
        return `<span class="font-extrabold">${direction}</span> ${value} from ${past} in ${this.yearB}`
      }
      return ''
    }
  }
}
</script>
